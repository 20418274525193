import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications";
import { _getDocuments, updateDocument } from '@/@core/queries/documents';
import i18n from '@/libs/i18n';
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useDocumentList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { handleError } = useCommon()
  const { invokeFunction, updateItem } = realmConnection()
  const userData = store.state?.userStore?.userData
  const documents = ref([])
  const collection = 'document'

  // Table Headers
  const tableColumns = computed(() => {
    return [
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const totalDocuments = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const defaultSortBy = 'name'
  const sortBy = ref(defaultSortBy)
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchDocuments = async () => {
    isLoading.value = true

    try {
      const input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        query: {},
        search: searchQuery.value,
        sortBy: sortBy.value || defaultSortBy,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        client_id: ( userData.role != "admin" ) ? userData.client.$oid : null
      }
    
      const items = await invokeFunction({ name: 'documentsQueryWithOffset', arg: input })

      documents.value = items?.documents || []
      if (currentPage.value === 1) totalDocuments.value = items?.totalRecords || 0
    } catch (error) {
      console.log(error)
      handleError({ error, defaultMessage: i18n.t('message.document_list_fetch_error') })
    } finally {
      isLoading.value = false
    }
  }

  const deleteDocument = async (id) => {
    try {
      const query = { _id: id }
      const action = { $set: { deleted: true } }

      await updateItem({ collection, query, action })

      fetchDocuments()
      showSuccessMessage(i18n.t('message.document_remove'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.something_went_wrong'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (documents.value.length ? 1 : 0)
    const to = documents.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalDocuments.value })
  })

  watch([currentPage, perPage], () => {
    fetchDocuments()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchDocuments()
  })

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    totalDocuments,
    showingMessage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    documents,
    deleteDocument,
    isLoading,
  }
}