<template>
  <b-card>
    <b-row>
      <b-col sm="8" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'organization-document-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Document') }}
        </b-button>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <b-form-input
            v-model="searchQuery"
            type="search"
            class="d-inline-block"
            :placeholder="$t('message.search')"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          :items="documents"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(actions)="data">
            <div class="flex flex-column">
              <ViewButton @clicked="openDocument(data.item.key)"/>
              <EditButton @clicked="router.push({name: 'organization-document-edit', params: { id: data.item._id }})"/>
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteDocument(data.item._id)"
              >
                {{ $t('message.confirm_delete_document') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="perPageOptions"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalDocuments"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useDocumentList from './useDocumentList'
import useCommon from '@/views/organization/useCommon'

export default {
  components: {
    BTable,
    BPagination,
    EditButton,
    ViewButton,
    DeleteButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const { router } = useRouter()
    const {
      fetchDocuments,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      totalDocuments,
      showingMessage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      documents,
      deleteDocument,
      isLoading,
    } = useDocumentList()

    const { openDocument } = useCommon()

    onMounted(() => {
      fetchDocuments()
    })

    return {
      fetchDocuments,
      documents,
      showingMessage,
      router,
      tableColumns,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteDocument,
      totalDocuments,
      currentPage,
      perPageOptions,
      perPage,
      isLoading,
      openDocument,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
